.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}



.notification-button {
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: 5px 10px;
  color: #3b5998;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.notification-button.active {
  color: #fff;
  background-color: #3b5998;
}

.notification-button:hover:not(.active) {
  background-color: #f2f2f2;
}

.notification-list {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
}

.notification-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.notification-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.notification-team {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.notification-title {
  font-size: 14px;
  margin-top: 5px;
}

.notification-button.delete {
  color: #e74c3c;
  margin-left: 10px;
}

.notification-button.check {
  color: #27ae60;
}

.notification-sidebar {
  background-color: white;
  height: 100%;
  width: 400px;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .notification-sidebar {
    width: 100%;
    top: 0;
    height: 100vh;
  }

  .notification-list {
    max-height: calc(100vh - 120px);
  }

  .notification-header {
    margin-top: 50px;
  }
}
