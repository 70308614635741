
:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #4723D9;
  --first-color-light: #AFA5D9;
  --white-color: #F7F6FB;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  }
  *,
  ::before,
  ::after {
  box-sizing: border-box;
  }
  body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: .5s;
  }
  a {
  text-decoration: none;
  }
  .header {
  width: 100%;
  height: var(--header-height);
  /* position: fixed; */
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: 10000;
  transition: .5s;
  }
  .header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
  }
  .header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  }
  .header_img img {
  width: 40px;
  }
  .l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: #111;
  padding: .5rem 1rem 0 0;
  transition: .5s;
  z-index: var(--z-fixed);
  }
  .nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  }
  .nav_logo,
  .nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: .5rem 0 .5rem 1.5rem;
  }
  .nav_logo {
  margin-bottom: 2rem;
  }
  .nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
  }
  .nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
  }
  .nav_name{
  text-decoration: none;
  }
  .nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: .3s;
  }
  .nav_link:hover {
  color: var(--white-color);
  }
  .nav_icon {
  font-size: 1.25rem;
  }
  .show {
  left: 0;
  }
  .body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
  }
  .active {
  color: var(--white-color);
  }
  .active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
  }
  .height-100 {
  height: 100vh;
  }
  @media screen and (min-width: 768px) {
  /* body {
  margin: calc(var(--header-height) + 1rem) 0 0 0;
  padding-left: calc(var(--nav-width) + 2rem);
  } */
  .header {
  height: calc(var(--header-height) + 1rem);
  padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }
  .header_img {
  width: 40px;
  height: 40px;
  }
  .header_img img {
  width: 45px;
  }
  .l-navbar {
  left: 0;
  padding: 1rem 1rem 0 0;
  }
  .show {
  width: calc(var(--nav-width) + 156px);
  }
  .body-pd {
  padding-left: calc(var(--nav-width) + 170px);
  }
  }
  .sidenav-1 {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 65px;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  }
  .sidenav-1 a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
  }
  .sidenav-1 a:hover {
  color: #f1f1f1;
  }
  .sidenav-1 .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-right: 50px;
  }
  #main {
  transition: margin-left .5s;
  margin-right: 250px;
  padding-left: 20px;
  }
  @media screen and (max-height: 450px) {
  .sidenav-1 {padding-top: 15px;}
  .sidenav-1 a {font-size: 18px;}
  }
  a {
  text-decoration: none !important;
  }

  .teams-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

 .no-scroll{
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.no-scroll::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
    width: 0;
    height: 0;
  }

