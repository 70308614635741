.comment {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  }
  
  .comment-image {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  }
  
  .comment-user {
  font-weight: bold;
  /* font-style: italic; */
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  }
  
  .comment-text {
    margin-bottom: 10px;
    line-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    text-align: justify;
    text-justify: inter-word;
  }
  
  .comment {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  }
  
  .comment-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  }
  
  .comment-form input {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  padding-left: 15px;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  margin-right: 10px;
  border: 1px solid #ddd;
  outline: none;
  }
  
  .comment-form button {
  background-color: lightblue;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-right: 10px;
  }
  
  .comment-form span {
  font-style: italic;
  }
  
  .comment-replies {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }
  
  .comment-replies .comment {
  margin-bottom: 20px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  }
  
  .comment-replies .comment:last-child {
  margin-bottom: 0;
  }
  
  .comment-replies .comment-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  }
  
  .comment-replies .comment-user {
  font-weight: bold;
  text-transform: uppercase;
  }
  
  .comment-replies .comment-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  }
  
  .comment-replies .comment-text.see-more-expanded {
    white-space: normal;
    margin-top: 10px;
    }
    
    .comment-replies .comment-text .more {
    display: none;
    }
    
    .comment-replies .comment-text .more:target {
    display: inline;
    }
    
    .comment-replies .comment-text .more-link {
    display: inline-block;
    margin-top: 5px;
    color: blue;
    cursor: pointer;
    }
    
    .comment-replies .comment-text .more-link:hover {
    text-decoration: underline;
    }
    
    .comment-replies .comment-text .less-link {
    display: inline-block;
    margin-top: 5px;
    color: blue;
    cursor: pointer;
    }
    
    .comment-replies .comment-text .less-link:hover {
    text-decoration: underline;
    }
    
    .comment-replies .comment-date {
    font-size: 12px;
    color: gray;
    margin-top: 5px;
    }
    
    /* Responsive styles */
    @media only screen and (max-width: 600px) {
    .comment-image {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    }
    
    .comment-user {
    font-size: 14px;
    line-height: 20px;
    }
    
    .comment-text {
    font-size: 14px;
    line-height: 20px;
    }
    
    .comment-form input {
    font-size: 12px;
    }
    
    .comment-form button {
    padding: 3px 6px;
    font-size: 12px;
    }
    
    .comment-replies .comment-image {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    }
    
    .comment-replies .comment-user {
    font-size: 14px;
    }
    
    .comment-replies .comment-text {
    font-size: 14px;
    line-height: 20px;
    }
    
    .comment-replies .comment-form input {
    font-size: 12px;
    }
    
    .comment-replies .comment-form button {
    padding: 3px 6px;
    font-size: 12px;
    }
    
    .comment-replies .comment-date {
    font-size: 10px;
    }
    }
    
    
    
      