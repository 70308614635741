.terms-paragraph {
  margin-top:10px;
  margin-bottom: 10px;
}

.terms-paragraph h2{
  font-weight: 600;
  padding-bottom:5px;
}

.terms-paragraph ul {
  list-style-type: disc;
  list-style-position: inside;
}

li {
  padding-left: 14px;
}

.terms-header h1 {
  font-weight: 600;
  font-size: x-large;
  margin:10px;
}

.terms-header h2 {
  font-weight: 600;
  font-size: large;
}

.terms-header a {
  color: blue;
  text-decoration: underline;
 
}

.terms-paragraph a {
  color: blue;
  text-decoration: underline;
 
}



#one{
  scroll-margin-top: 50px;
}

#two{
  scroll-margin-top: 50px;
}

#three{
  scroll-margin-top: 50px;
}

#four{
  scroll-margin-top: 50px;
}
#five{
  scroll-margin-top: 50px;
}

#six{
  scroll-margin-top: 50px;
}

#seven{
  scroll-margin-top: 50px;
}

#eight{
  scroll-margin-top: 50px;
}

#nine{
  scroll-margin-top: 50px;
}

#ten{
  scroll-margin-top: 50px;
}

#eleven{
  scroll-margin-top: 50px;
}
#twelve{
  scroll-margin-top: 50px;
}

#thirteen{
  scroll-margin-top: 50px;
}

#fourteen{
  scroll-margin-top: 50px;
}

#fifteen{
  scroll-margin-top: 50px;
}

#sixteen{
  scroll-margin-top: 50px;
}

#seventeen{
  scroll-margin-top: 50px;
}

#eighteen{
  scroll-margin-top: 50px;
}
#ninteen{
  scroll-margin-top: 50px;
}

#twenty{
  scroll-margin-top: 50px;
}

#twentyone{
  scroll-margin-top: 50px;
}

#twentytwo{
  scroll-margin-top: 50px;
}

#twentythree{
  scroll-margin-top: 50px;
}

#twentyfour{
  scroll-margin-top: 50px;
}

#twentyfive{
  scroll-margin-top: 20px;
}
#twentysix{
  scroll-margin-top: 50px;
}

#twentyseven{
  scroll-margin-top: 50px;
}

#twentyeight{
  scroll-margin-top: 50px;
}



