@tailwind base;
@tailwind components;
@tailwind utilities;

  .dropdown-area{
      background-color: rgba(226, 226, 226, 0.982);
      border-radius: 7px;
      padding: 10px;
      overflow: hidden;
      position: relative;
      z-index: 9;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    max-width: 600px;
    padding:10px;
    margin-top: 0px;
    border-radius: 4px;
  }
  .dropdown-content::before {
      content:'...';
      width: 20px !important;
      height: 20px !important;
      background-color: #f6f6f6;
      z-index: 9;
      position: absolute;
  top: -8px;
  left: 0.6em;
 
  transform: rotate(135deg);
 
  }
  .dropbtn{
      background-color: #2e343b;
      color: #fff;
      font-weight: bold;
  }
  .dropbtn:hover{
      background-color: transparent;
      color:#20b8ff;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

@media screen and (max-width:900px) {
 
.css-k008qs{
    display: block !important;
    /* background-color: aquamarine; */
}   
}

