
.tlink-division
{
  font-size:larger;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5px;
  color:#000

}
 
.tlink-name {
  text-align: center;
  font-weight: 600;
  color:#000;
  margin-bottom: 5px;
} 
