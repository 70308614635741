.user-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
.gravatar {
    border-radius: 50%;
}
  
  .user-button {
    font-size: 14px;
    font-weight: bold;
    border: none;
    padding: 5px 10px;
    color: #3b5998;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
  }
  
  .user-button.active {
    color: #fff;
    background-color: #3b5998;
  }
  
  .user-button:hover:not(.active) {
    background-color: #f2f2f2;
  }
  
  .user-list {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  
  .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-decoration: none;
  }
  
  .user-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .user-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .user-team {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .user-title {
    font-size: 14px;
    margin-top: 5px;
  }
  
  .user-button.delete {
    color: #e74c3c;
    margin-left: 10px;
  }
  
  .user-button.check {
    color: #27ae60;
  }
  
  .user-sidebar {
    background-color: white;
    height: 100%;
    width: 400px;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  @media screen and (max-width: 768px) {
    .user-sidebar {
      width: 100%;
      top: 0;
      height: 100vh;
    }
  
    .user-list {
      max-height: calc(100vh - 120px);
    }
  
    .user-header {
      margin-top: 50px;
    }
  }
  