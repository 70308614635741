.news {
  width: 80%;
  margin: 25px auto;
  background-color: #f0f2f5;
  border-radius: 10px;
  padding-bottom: 20px;
}

.newsheader {
  display:flex;
  align-items: center;
}

.newsheaderimg {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow:hidden;
  
 

}

.newspost {
  margin-bottom:5px;
}

.newsheaderimg img {
  height:100%;
  width:100%;
  object-fit: cover;

}

.newsheadername {
  margin-left: 12px;
  font-weight: bold;
}

.date {
  color: silver;
  font-weight: 300;
  font-size: 14px;
}

.newsbody {
  width: 100%;
  display:flex;
  flex-direction: column;
}

.newsbodytest {
  width: 100%;
  margin:12px auto;
}

.newsbodyimg img {
  width: 100%;
  height: 100%;

}